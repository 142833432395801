import { useEffect, useState } from "react"
import styled from "styled-components"
import logo from "../imgs/logo.png";
import { Link } from "react-router-dom";
import { Main, Voltar } from "./GlobalStyled.js";

export default function Home() {    
    const [tamAnel, setTamAnel] = useState(5);
    
    const [aro, setAro] = useState(10)

    useEffect(() => {
        if (tamAnel < 5.1) {
            setAro("Aro: 10")
        } else if (tamAnel < 5.2) {
            setAro("Aro: 11")
        } else if (tamAnel < 5.3) {
            setAro("Aro: 12")
        } else if (tamAnel < 5.4) {
            setAro("Aro: 13")
        } else if (tamAnel < 5.5) {
            setAro("Aro: 14")
        } else if (tamAnel < 5.6) {
            setAro("Aro: 15")
        } else if (tamAnel < 5.7) {
            setAro("Aro: 16")
        } else if (tamAnel < 5.8) {
            setAro("Aro: 17")
        } else if (tamAnel < 5.9) {
            setAro("Aro: 18")
        } else if (tamAnel < 6.0) {
            setAro("Aro: 19")
        } else if (tamAnel < 6.1) {
            setAro("Aro: 20")
        } else if (tamAnel < 6.2) {
            setAro("Aro: 21")
        } else if (tamAnel < 6.3) {
            setAro("Aro: 22")
        } else if (tamAnel < 6.4) {
            setAro("Aro: 23")
        } else if (tamAnel < 6.5) {
            setAro("Aro: 24")
        } else if (tamAnel < 6.6) {
            setAro("Aro: 25")
        } else if (tamAnel < 6.7) {
            setAro("Aro: 26")
        } else if (tamAnel < 6.8) {
            setAro("Aro: 27")
        } else if (tamAnel < 6.9) {
            setAro("Aro: 28")
        } else if (tamAnel < 7.0) {
            setAro("Aro: 29")
        } else if (tamAnel < 7.1) {
            setAro("Aro: 30")
        } else if (tamAnel < 7.2) {
            setAro("Aro: 31")
        } else if (tamAnel < 7.3) {
            setAro("Aro: 32")
        } else if (tamAnel < 7.4) {
            setAro("Aro: 33")
        } else if (tamAnel < 7.5) {
            setAro("Aro: 34")
        } else {
            setAro("Aro: 35")
        }
    }, [tamAnel])
    
    return (
        <Container>
            
            <Instrucoes>
                <h2>Orientação:</h2>
                <h3>Arraste a bolinha até que indique a medida exata da circuferência do seu dedo, conforme você mediu seguindo as orientações da tela anterior.</h3>
            </Instrucoes>
            
            <h2>{tamAnel} cm</h2>
            <input 
            type="range" 
            name="tamanho" 
            min="5" 
            max="7.5" 
            step="0.1" 
            value={tamAnel} 
            onChange={e => setTamAnel(e.target.value)} 
            />
                <h1>{aro}</h1>
            <Footer>
                
                <a 
                href="https://www.lourdesaliancas.com.br/" 
                target="_blank" 
                rel="noreferrer"
                >
                    <button>Ir para o site</button>
                </a>
                <Link to={"/seguir-sem-anel"}>
                    <Voltar>Voltar</Voltar>
                </Link>
            </Footer>

        </Container>
    )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  height: 700px; 
  position: relative;

  @media (min-width: 1300px) {
    height: 90vh;
  }
    

    h1 {
        height: 100px;
        font-size: 40px;
        font-weight: bold;
        text-align: center;
        display: flex;
        align-items: center;
        margin-top: 50px;

        @media (min-width: 1300px) {
            font-size: 60px;
            line-height: 80px;
        }
    }

    h2 {
        font-weight: bold;
        line-height: 40px;
        font-size: 20px;
        @media (min-width: 1300px) {
            font-size: 40px;
            line-height: 80px;
          }
    }

    h3 {
        line-height: 22px;
        font-size: 16px;
        margin-bottom: 2vh;
        @media (min-width: 1300px) {
            font-size: 25px;
            line-height: 50px;
          }
    }

    input {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 5px;
    background-color: #F7EDE4;
    outline: none;
    width: 60vw;
    height: 10px;
    margin: 10px 20px;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1); 
    }

    input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%; 
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);   
    background: #a36974;     
    cursor: pointer;
    }

    input::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%; 
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
    background: #a36974;
    cursor: pointer;
    }

   
`
const Footer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 90vw;
padding: 0 3%;
text-align: center;
position: absolute;
bottom: 10px;

@media (min-width: 1300px) {
  padding: 0 10%;
}
    button {
        width: 80vw;
        height: auto;
        margin: 20px;
        padding: 20px;
        background-color: #a36974;
        color: #FFFFFF;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 40px;
        cursor: pointer;
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
    }
   
`



const Instrucoes = styled.div`
width: 80vw;
text-align: left;
margin: 3vh 0 50px 0;
`