import styled from "styled-components";
import logo from "../imgs/logo.png";
import { Link } from "react-router-dom";
export default function Home() {
  return (
    <Container>
      <Topo>
        <img src={logo} alt="Logo Lourdes Alianças" />
      </Topo>
    
      <Botoes>
        <Link to={"/Calibrar"}>
        <button>Vamos medir</button>
        </Link>

        <Link to={"/seguir-sem-anel"}>
        <Botao2>Não tenho o anel</Botao2>
        </Link>
        <a href="https://www.lourdesaliancas.com.br/" target="_blank" rel="noreferrer">
                    <button>Ir para o site</button>
          </a>
      </Botoes>

    </Container>
  );
}

const Container = styled.div`
width: 100vw;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

  button {
    width: 80vw;
    height: auto;
    margin: 9px;
    padding: 20px;
    background-color: #a36974 ;
    color: #ffffff;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 40px;
    cursor: pointer;
  }
  h2 {
    line-height: 20px;
    font-size: 20px;
  }
`;

const Topo = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  margin-top: 30px;

  img {
    width: 70%;
    max-width: 300px;
    height: auto;
    position: inherit;
    margin-bottom: 100px;
  }
`;

const Botoes = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
position: fixed;
bottom: 20vh;
@media (min-width: 1024px) {
  bottom: 5vh;
}
`;

const Botao2 = styled.button`
background-color: #4d4d4d !important;
color: #ffffff !important;
`;
