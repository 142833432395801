import { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import UserContext from "../context/UserContext"; 
import { Link } from "react-router-dom";

import anel from "../imgs/anel.png"

export default function Home() {
    const { umCm } = useContext(UserContext);
    
    const [tamAnel, setTamAnel] = useState(150);
    
    const tamAnelCm = tamAnel / umCm;

    const [aro, setAro] = useState(10)

    useEffect(() => {
        if (tamAnelCm < 1.45) {
            setAro("Aro: ##")
        } else if (tamAnelCm <= 1.48) {
            setAro("Seu tamanho é: 10")
        } else if (tamAnelCm <= 1.52) {
            setAro("Seu tamanho é: 11")
        } else if (tamAnelCm <= 1.55) {
            setAro("Seu tamanho é: 12")
        } else if (tamAnelCm <= 1.58) {
            setAro("Seu tamanho é: 13")
        } else if (tamAnelCm <= 1.62) {
            setAro("Seu tamanho é: 14")
        } else if (tamAnelCm <= 1.65) {
            setAro("Seu tamanho é: 15")
        } else if (tamAnelCm <= 1.68) {
            setAro("Seu tamanho é: 16")
        } else if (tamAnelCm <= 1.72) {
            setAro("Seu tamanho é: 17")
        } else if (tamAnelCm <= 1.75) {
            setAro("Seu tamanho é: 18")
        } else if (tamAnelCm <= 1.78) {
            setAro("Seu tamanho é: 19")
        } else if (tamAnelCm <= 1.82) {
            setAro("Seu tamanho é: 20")
        } else if (tamAnelCm <= 1.85) {
            setAro("Seu tamanho é: 21")
        } else if (tamAnelCm <= 1.88) {
            setAro("Seu tamanho é: 22")
        } else if (tamAnelCm <= 1.92) {
            setAro("Seu tamanho é: 23")
        } else if (tamAnelCm <= 1.95) {
            setAro("Seu tamanho é: 24")
        } else if (tamAnelCm <= 1.99) {
            setAro("Seu tamanho é: 25")
        } else if (tamAnelCm <= 2.02) {
            setAro("Seu tamanho é: 26")
        } else if (tamAnelCm <= 2.05) {
            setAro("Seu tamanho é: 27")
        } else if (tamAnelCm <= 2.08) {
            setAro("Seu tamanho é: 28")
        } else if (tamAnelCm <= 2.12) {
            setAro("Seu tamanho é: 29")
        } else if (tamAnelCm <= 2.15) {
            setAro("Seu tamanho é: 30")
        } else if (tamAnelCm <= 2.18) {
            setAro("Seu tamanho é: 31")
        } else if (tamAnelCm <= 1.22) {
            setAro("Seu tamanho é: 32")
        } else if (tamAnelCm <= 2.25) {
            setAro("Seu tamanho é: 33")
        } else if (tamAnelCm <= 2.28) {
            setAro("Seu tamanho é: 34")
        } else if (tamAnelCm <= 2.32) {
            setAro("Seu tamanho é: 35")
        } else {
            setAro("Aro: ##")
        }
    }, [tamAnelCm])
    
    return (
        <Container>
            <Cont2>
            <BoxTamanho>
                <CirculoMaior>
                    <Circulo tamAnel={`${tamAnel}px`}>
                        <img src={anel} alt="anel"></img>
                        </Circulo>
                </CirculoMaior>
            </BoxTamanho>

            <h1>{aro}</h1>
            </Cont2>
            <Instrucoes>
                <h2>Como medir:</h2>
                <h3>Posicione o anel na figura acima e ajuste-a até que fiquem do mesmo tamanho.</h3>
                <h4>A borda rosa precisa ficar colada na parte de dentro do anel mas sem desaparecer.</h4>
            </Instrucoes>

            <Botoes>
            <input 
            type="range" 
            name="tamanho" 
            min="50" 
            max="200" 
            step="0.5" v
            alue={tamAnel} 
            onChange={e => setTamAnel(e.target.value)}
             />
                <a href="https://www.lourdesaliancas.com.br/" target="_blank" rel="noreferrer">
                    <button>Ir para o site</button>
                </a>
                <Link to={"/calibrar"}>
                    <Voltar>Voltar</Voltar>
                </Link>
            </Botoes>

        </Container>
    )
}

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20%;

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    h1 {
        height: 100px;
        font-size: 35px;
        text-align: center;
        display: flex;
        align-items: center;
        font-weight: bold;
    }

    h2 {
        font-weight: bold;
        line-height: 40px;
        font-size: 20px;
    }

    h3, h4 {
        line-height: 22px;
        font-size: 14px;
    }

    h4 {
        font-weight: bold;
    }
    input {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 5px;
    background: #F7EDE4;
    outline: none;
    width: 70vw;
    height: 10px;
    margin: 0px 45px;
    }

    input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;   
    background: #a36974;    
    cursor: pointer;
    }

    input::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%; 
    background: #a36974;
    cursor: pointer;
    }
`

const BoxTamanho = styled.div`
width: 80vw;
height: 30vh;
align-items: left;
margin-top: 3vh;
border-left: 1px solid black;
border-bottom: 1px solid black;
position: relative;

@media (min-width: 1300px) {
  margin-top: 20vh;
  width: 35vw;
  margin-right: 5vw;
}
`;
const CirculoMaior = styled.div`
width: ${(props) => props.tamAnel};
  height: ${(props) => props.tamAnel};
  padding: 5px;
    background-color: #a36974;
    border-radius: 100px;
    position: absolute;
    bottom: 0px;
`

const Circulo = styled.div`
width: ${(props) => props.tamAnel};
height: ${(props) => props.tamAnel};
border-radius: 100px;
    background-image: ${anel};

    img {
        border-radius: 100px;
        max-height: 100%;
        max-width: 100%;
    }
`

const Botoes = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    bottom: 5vh;

    button {
        width: 80vw;
        height: auto;
        margin: 20px;
        padding: 20px;
        background: #a36974;
        color: #FFFFFF;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 40px;
        cursor: pointer;
    }
`

const Voltar = styled.div`
    display: flex;
    align-items: center;
    color: #4d4d4d;
    cursor: pointer;
    font-size: 20px;
`

const Instrucoes = styled.div`
    width: 80vw;
    text-align: left;
`

const Cont2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
