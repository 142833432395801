import { BrowserRouter, Routes, Route } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import UserContext from "../context/UserContext";
import { useState } from "react";
import Home from "./Home";
import Calibrar from "./Calibrar";
import MedirComAnel from "./medir-com-anel";

import SeguirSemAnel from "./seguir-sem-anel";
import MedirSemAnel from "./medir-sem-anel";

import planoFundo from "../imgs/plano-fundo.png";

export default function App() {
  const [tamCartao, setTamCartao] = useState("400");
  const umCm = tamCartao / 5.4;

  return (
    <>
      <Wallpaper src={planoFundo}></Wallpaper>
      <BrowserRouter>
        <GlobalStyled />
        <UserContext.Provider value={{ tamCartao, setTamCartao, umCm }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Calibrar" element={<Calibrar />} />
            <Route path="/medir-com-anel" element={<MedirComAnel />} />

            <Route path="/seguir-sem-anel" element={<SeguirSemAnel />} />
            <Route path="/medir-sem-anel" element={<MedirSemAnel />} />
          </Routes>
        </UserContext.Provider>
      </BrowserRouter>
    </>
  );
}

export const GlobalStyled = createGlobalStyle`
    ${reset}
    
    *, body {
        box-sizing: border-box;
        cursor: default;
        font-family: 'Roboto', sans-serif;
    a {
        text-decoration: none;
    }
    }
`;


const Wallpaper = styled.img`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  opacity: 0.4;
  @media (min-width: 1024px) {
   
  }
`;
