import styled from "styled-components"
import { Link } from "react-router-dom";
import px2vw from "../utils/px2vw";
import gif from "../imgs/medir-com-barbante.gif";
export default function Calibragem () {

    return (
        <Container>

            <ImgGif>
                <img src={gif} alt="instrucao"/>
            </ImgGif>
            
            <Instrucoes>
                <h2>Como medir:</h2>
                <h3>1 - Enrole um barbante em seu dedo.</h3>
                <h3>2 - Marque no barbante o ponto onde se encontraram.</h3>
                <h3>3 - Estenda o barbante sobre uma régua e anote o valor do comprimento entre os dois pontos.</h3>
            </Instrucoes>

            <Botoes>
                <Link to={"/medir-sem-anel"}>
                    <button>Tenho a medida!</button>
                </Link>
                <Link to={"/"}>
                    <Voltar>Voltar</Voltar>
                </Link> 
            </Botoes>
            
            
            
        </Container>
    )
}
const Container = styled.div`
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2vh;

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    h2 {
        font-weight: bold;
        line-height: 30px;
        font-size: 20px;
    }

    h3 {
        line-height: 22px;
        font-size: 16px;
    }
    
    @media (min-width: 1024px) {
        width: 100vw;
        height: 100vh;
        }
`

const ImgGif = styled.div`
    width: 80vw;
    height: auto;
    margin: 3vh 0;
    
    img {
        border-radius: 10px;
        max-height: 100%;
        max-width: 100%;
    }
    @media (min-width: 1024px) {
        width: ${px2vw(500)};
        min-height: ${px2vw(300)};
        height: 100%;
      }
`

const Botoes = styled.div`
        button {
        width: 80vw;
        height: auto;
        margin: 20px;
        padding: 20px;
        background-color: #a36974;
        color: #FFFFFF;
        font-size: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 40px;
        cursor: pointer;
    }
    @media (max-width: 791px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: fixed;
        bottom: 5vh;
      }
    
`

const Voltar = styled.div`
    display: flex;
    align-items: center;
    color: #4d4d4d;
    cursor: pointer;
    font-size: 19px;
`

const Instrucoes = styled.div`
    width: 80vw;
    text-align: left;

    @media (min-width: 1024px) {
        width: 90vw;
    }
`